import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";
import PageLayout from "../components/PageLayout";
import {
  TITLE_FONTSIZE,
  SUBTITLE_FONTSIZE,
  TEXT_FONTSIZE,
  APN_SETTINGS,
  APN_OS_MENU,
  APN_PROVIDER_SETTINGS,
  APN_OS_TROUBLESHOOT_STEPS,
} from "../utilities/constants";
import { useDispatch } from "react-redux";
import { saveRouteDetails } from "../redux/actions/routeActions";
import {Container, Dropdown, Image } from "react-bootstrap";

const MyContainer = styled.div`
  padding: 10%;
  background-color: white;
`;

const TitleArea = styled.div`
  display: flex;
  margin-bottom: 20px;
  width: 45rem;
  justify-content: space-between;
  @media (max-width: 805px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Title = styled.div`
  font-size: ${TITLE_FONTSIZE};
  font-weight: 600;

  @media (max-width: 450px) {
    font-size: 30px;
  }
  @media (max-width: 380px) {
    font-size: 25px;
  }
`;

const APNDetail = styled.div`
  margin-top: 30px;
  font-size: ${TEXT_FONTSIZE};
  font-weight: 400;
  p {
    font-size: ${TEXT_FONTSIZE};
    font-weight: 400;
  }
`;

const ScreenshotContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 30px;
`;

const ScreenshotImage = styled.img`
  width: 300px;
  margin-right: 20px; 
`;

const DetailTitle = styled.div`
  color: #0494ca;
  font-weight: 500;
  font-size: ${SUBTITLE_FONTSIZE};
  margin-bottom: 20px;
`;
const DetailDescription = styled.div``;
const DetailSubtitle = styled.div`
  font-size: ${SUBTITLE_FONTSIZE};
  font-weight: 600;
  margin-bottom: 5px;
`;

const DetailParagraph = styled.div`
  min-height: 5px;
`;

const DetailParagraphBold = styled.div`
  min-height: 5px;
  font-weight: 600;
`;

const DropdownContainer = styled.div`
  margin-bottom: 15px;
  width: 200px;
`;

const formatString = (text: string) => {
  const stringList = text.split("\n");
  return (
    <>
      {stringList.map((s, i) => {
        if (s.trim() === '<b>Cancellation and Refund Policy</b>') {
          let boldedContent = s.substring(3, s.length - 4);
          return (
            <DetailParagraphBold key={i} id='selectedContent'>
              <Trans>{boldedContent}</Trans>
            </DetailParagraphBold>
          )
        } else if (s.startsWith('<b>') && s.endsWith('</b>')) {
          let boldedContent = s.substring(3, s.length - 4);
          return (
            <DetailParagraphBold key={i}>
              <Trans>{boldedContent}</Trans>
            </DetailParagraphBold>
          )
        }
        return (
          <DetailParagraph key={i}>
            <Trans>{s}</Trans>
          </DetailParagraph>
        )
      })}
    </>
  );
};

const DEFAULT_OS = "iOS";
const DEFAULT_PROVIDER = "Telus";

const apnSetting = () => {
  const phoneImage = '/images/apn-setting/s1.jpg';
  // const phoneImages = ['/images/apn-setting/s1.jpg', '/images/apn-setting/s2.jpg', '/images/apn-setting/s3.jpg', '/images/apn-setting/s4.jpg','/images/apn-setting/s5.jpg'];
  const [os, setOs] = useState(DEFAULT_OS);
  const [provider, setProvider] = useState(DEFAULT_PROVIDER);

  const phoneImages = [
    { id: 1, src: '/images/apn-setting/s1.jpg', alt: 'Image 1' },
    { id: 2, src: '/images/apn-setting/s2.jpg', alt: 'Image 2' },
    { id: 3, src: '/images/apn-setting/s3.jpg', alt: 'Image 3' },
    { id: 4, src: '/images/apn-setting/s4.jpg', alt: 'Image 4' },
    { id: 5, src: '/images/apn-setting/s5.jpg', alt: 'Image 5' },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(saveRouteDetails("apn-setting") as any);
  }, []);

  const handleOsChange = (option) => {
    setOs(option);
  };

  const handleProviderChange = (option) => {
    setProvider(option);
  };

  return (
    <PageLayout>
      <title>APN | PhoneBox</title>
      <Container>
        <div className="p-5 mt-5">
          <TitleArea>
            <Title>
              <Trans>{"APN Settings"}</Trans>
            </Title>
          </TitleArea>
          <APNDetail>
            <DetailSubtitle>
              <Trans>{APN_SETTINGS.step_one_title}</Trans>
            </DetailSubtitle>
            <DetailDescription>
              <div>
                <Trans>{formatString(APN_SETTINGS.step_one_description)}</Trans>
              </div>
            </DetailDescription>

          {/* OS Selector */}
          </APNDetail>
          <APNDetail>
            <DetailSubtitle>
              <Trans>{APN_SETTINGS.step_two_title}</Trans>
            </DetailSubtitle>
            <DropdownContainer>
              <Dropdown>
                <Dropdown.Toggle variant="primary" className="w-100">
                  {os || DEFAULT_OS}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {APN_SETTINGS.OS_options.map(option => (
                      <Dropdown.Item key={option} onClick={() => handleOsChange(option)}>
                        <Trans>{option}</Trans>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                
              </Dropdown>
            </DropdownContainer>
          </APNDetail>
          

          {/* Provider Selector */}
          {os === 'Android' && (
            <APNDetail>
              <DetailSubtitle>
                <Trans>{APN_SETTINGS.step_three_title}</Trans>
              </DetailSubtitle>
              <DropdownContainer>
                <Dropdown>
                  <Dropdown.Toggle variant="primary" className="w-100">
                    {provider || DEFAULT_PROVIDER}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                      {APN_SETTINGS.provider_options.map(option => (
                        <Dropdown.Item key={option} onClick={() => handleProviderChange(option)}>
                          <Trans>{option}</Trans>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  
                </Dropdown>
              </DropdownContainer>
            </APNDetail>
          )}
          
          {/* APN Settings Header*/}
          <APNDetail>
            <DetailSubtitle>
              <Trans>{APN_OS_MENU[os].apn_title}</Trans>
            </DetailSubtitle>
            <DetailDescription>
              <div>
                <Trans>{APN_OS_MENU[os].apn_description}</Trans>
              </div>
            </DetailDescription>
          </APNDetail>

          {os === 'Android' && (
            <div>
              {/*Screenshots */}
              <ScreenshotContainer>
              {phoneImages.map((image) => (
                <div key={image.id} className="mb-3">
                  <ScreenshotImage src={image.src} alt={image.alt} />
                </div>
              ))}
            </ScreenshotContainer>

            {/* APN Settings Details */}
            <APNDetail>
              <DetailDescription>
                <Trans>
                  {APN_PROVIDER_SETTINGS.prompt}
                </Trans>
              </DetailDescription>
              <DetailDescription>
                <ul>
                  {APN_PROVIDER_SETTINGS[provider].map((item, index) => (
                    <li key={index}>
                      <strong>{item.label}:</strong> {item.value}
                    </li>
                  ))}
                </ul>
              </DetailDescription>
            </APNDetail>
            </div>
          )}
          {os === 'iOS' && (
            <div style={{ marginTop: '10px' }}>
              <ol>
                {APN_OS_TROUBLESHOOT_STEPS.map((step, index) => (
                  <li key={index}>{step}</li>
                ))}
              </ol>
            </div>
          )}

        </div>
      </Container>
    </PageLayout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default apnSetting;


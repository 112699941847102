import { constants } from "../constants";

export const saveRouteDetails = (routeString) => (dispatch) => {
  dispatch({
    type: constants.ROUTE_PATH,
    payload: routeString,
  });
};

export const loginUser = () => {
  return { type: constants.LOGIN };
};

export const logoutUser = () => {
  return { type: constants.LOGOUT };
};
